import {
  Box,
  Typography,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Button
} from '@mui/material'
import { Helmet } from 'react-helmet-async'

import { styled } from '@mui/material/styles'
import TelegramIcon from '@mui/icons-material/Telegram'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Link } from 'react-router-dom'
import { myTelegramLink } from 'src/config'

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
)

export default function About() {
  return (
    <>
      <Helmet>
        <title>About - Kirsanov</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <Container maxWidth="sm">

              <Typography
                variant="h2"
                sx={{ mt: 4, mb: 2 }}
              >
                Web Developer
              </Typography>
              <Typography
                sx={{ marginTop: 3, textAlign: 'center', fontSize: { xs: 20 } }}
              >
                Hello everyone, I have been working as a web developer since 2015.
                Graduated from college with a degree in programming.
              </Typography>
              <Typography
                sx={{ marginTop: 3, textAlign: 'center', fontSize: { xs: 20 } }}
              >
                Instead of 1000 words, look at my portfolio and what projects I participated in
              </Typography>
              <Button
                component={Link}
                to="/portfolio"
                sx={{ marginTop: 3, }}
              >
                Portfolio
              </Button>
            </Container>
          </Box>
          <Divider sx={{ my: 4 }} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {/* <Box>
              <Typography
                component="span"
                variant="subtitle1"
              >
                Phone:
                {' '}
              </Typography>
              <Typography
                component="span"
                variant="subtitle1"
                color="text.primary"
              >
                +7 962 222 95 95
              </Typography>
            </Box> */}
            <Box>
              <Tooltip
                arrow
                placement="top"
                title="Telegram"
              >
                <IconButton
                  color="primary"
                  href={myTelegramLink}
                >
                  <TelegramIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip
                arrow
                placement="top"
                title="WhatsApp"
              >
                <IconButton color="primary">
                  <WhatsAppIcon />
                </IconButton>
              </Tooltip> */}
            </Box>
          </Box>
        </Container>
      </MainContent>
    </>
  )
}
