/* eslint-disable react/function-component-definition */
import { FC, ReactNode, useRef } from 'react'
import PropTypes from 'prop-types'
import { Outlet, useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { myTelegramLink } from 'src/config'

interface MainLayoutProps {
  children?: ReactNode;
}

const OverviewWrapper = styled(Box)(
  () => `
  .button_container {
    position: fixed;
    top: 35px;
    right: 37px;
    height: 27px;
    width: 35px;
    cursor: pointer;
    z-index: 100;
    transition: opacity 0.25s ease;
  }
  @media screen and (max-width: 480px) {
    .button_container {
      // width: 25px;
      right: 15px;
    }
  }
  
  .button_container:hover {
    opacity: 0.7;
  }
  
  .button_container.active {
    background: none
  }

  .button_container.active .top {
    transform: translateY(11px) translateX(0) rotate(45deg);
    background: #fff;
  }
  
  .button_container.active .middle {
    opacity: 0;
    background: #fff;
  }
  
  .button_container.active .bottom {
    transform: translateY(-11px) translateX(0) rotate(-45deg);
    background: #fff;
  }
  
  .button_container span {
    background: #fff;
    border: none;
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .button_container span:nth-of-type(2) {
    top: 11px;
  }
  
  .button_container span:nth-of-type(3) {
    top: 22px;
  }
  
  .overlay {
    position: fixed;
    z-index: 1;
    /*background: #223746;*/
    // background: #4f1f54;
    background: linear-gradient(180deg, #1d516d, #4f1f54);
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    display: block;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    overflow: hidden;
  }
  
  .overlay.open {
    opacity: 0.9;
    visibility: visible;
    height: 100%;
  }
  
  .overlay.open li {
    animation: fadeInRight 0.5s ease forwards;
    animation-delay: 0.35s;
  }
  
  .overlay.open li:nth-of-type(2) {
    animation-delay: 0.4s;
  }
  
  .overlay.open li:nth-of-type(3) {
    animation-delay: 0.45s;
  }
  
  .overlay.open li:nth-of-type(4) {
    animation-delay: 0.5s;
  }
  
  .overlay nav {
    position: relative;
    height: 70%;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    font-size: 36px;
    font-weight: 400;
    text-align: center;
  }
  
  .overlay ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100%;
  }
  
  .overlay ul li {
    display: block;
    height: 25%;
    height: calc(100% / 4);
    min-height: 50px;
    position: relative;
    opacity: 0;
  }
  
  .overlay ul li a {
    display: block;
    position: relative;
    color: #fff;
    text-decoration: none;
    overflow: hidden;
  }
  
  .overlay ul li a:hover::after,
  .overlay ul li a:focus::after,
  .overlay ul li a:active::after {
    width: 100%;
  }
  
  .overlay ul li a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0%;
    transform: translateX(-50%);
    height: 3px;
    background: #fff;
    transition: 0.35s;
  }
  
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }

    100% {
      opacity: 1;
      left: 0;
    }
  }
`
)

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const navigate = useNavigate()

  const menuButton = useRef<HTMLDivElement>(null)
  const overlayDiv = useRef<HTMLDivElement>(null)

  const toggleMenu = () => {
    if (menuButton.current) {
      menuButton.current.classList.toggle('active')
    }

    if (overlayDiv.current) {
      overlayDiv.current.classList.toggle('open')
    }
  }

  const goRoute = (route: string) => {
    navigate(route)
    toggleMenu()
  }

  return (
    <OverviewWrapper
      sx={{
        flex: 1,
        height: '100%'
      }}
    >
      {/* <Box sx={{
        height: { xs: '60px', md: '90px' },
        zIndex: 1,
        position: 'fixed',
        width: '100%',
        background: '#070c27'
      }}
      > */}
      <Box
        sx={{
          height: { xs: '60px', md: '90px' },
          zIndex: 1,
          position: 'fixed',
          width: '100%'
        }}
        className="button_container"
        onClick={toggleMenu}
        ref={menuButton}
      >
        <span className="top" />
        <span className="middle" />
        <span className="bottom" />
      </Box>
      {/* </Box> */}
      <div
        className="overlay"
        ref={overlayDiv}
      >
        <nav className="overlay-menu">
          <ul>
            <li><a onClick={() => goRoute('/')}>Home</a></li>
            <li><a onClick={() => goRoute('/portfolio')}>Portfolio</a></li>
            <li><a onClick={() => goRoute('/about')}>About</a></li>
            <li>
              <a
                href={myTelegramLink}
                target="_blank"
                rel="noreferrer"
              >
                Contacts
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {children || <Outlet />}
    </OverviewWrapper>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node
}

export default MainLayout
